var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: { titulo: "Productos", impedirClose: _vm.impedirClose }
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.detalleProducto, stripe: "" } },
            [
              _c("el-table-column", {
                attrs: { label: "Nombre", prop: "productoNombre" }
              }),
              _c("el-table-column", {
                attrs: { label: "Cantidad", prop: "cantidad" }
              }),
              _c("el-table-column", {
                attrs: { label: "Subtotal" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              parseFloat(
                                props.row.productoPrecio * props.row.cantidad
                              ).toFixed(2)
                            )
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("div", { staticStyle: { width: "100%" } }, [
            _c("h4", { staticStyle: { "text-align": "end" } }, [
              _vm._v("Total: $" + _vm._s(_vm.total.toFixed(2)))
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }