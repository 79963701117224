<template>
  <div>
    <maca-modal titulo="Productos" :impedirClose="impedirClose" ref="modal">
      <el-table :data="detalleProducto" stripe>
        <el-table-column label="Nombre" prop="productoNombre"></el-table-column>
        <el-table-column label="Cantidad" prop="cantidad"></el-table-column>
        <el-table-column label="Subtotal">
          <template slot-scope="props">
            <span>{{
              parseFloat(props.row.productoPrecio * props.row.cantidad).toFixed(
                2
              )
            }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div style="width: 100%">
        <h4 style="text-align: end">Total: ${{ total.toFixed(2) }}</h4>
      </div>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "detalle-producto",
  data() {
    return {
      impedirClose: false,
      cargando: false,
      id: 0,
      total: 0,
      detalleProducto: [],
      persona: null,
    };
  },
  methods: {
    abrir(id, detalleProducto) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      this.total = 0;
      this.detalleProducto = [];
      this.id = id;
      this.detalleProducto = detalleProducto;
      this.calcularTotal();
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    calcularTotal() {
      this.detalleProducto.forEach((producto) => {
        this.total += parseFloat(producto.productoPrecio * producto.cantidad);
      });
    },
  },
};
</script>
